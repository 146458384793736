<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">成本中心</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item>成本中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">成本中心编码:</div>
          <el-input v-model="code" placeholder="请输入成本中心编码"></el-input>
        </div>

        <button class="btn-pre" @click="getCostCenters(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-pre btn-add" @click="newBuilt" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建
        </button>
          <button class="btn-pre" @click="uploadAllInfo">
          <i class="fa fa-upload"></i>导入
        </button>
         <button class="btn-pre" @click="downloadAll">
          <i class="fa fa-download"></i>导出
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          border
          height="500"
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
            :empty-text="loading?'加载中...':'暂无数据'"
        >
          <el-table-column type="" label="#" width="45">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="Id" label="Id" width="80" />
          <el-table-column prop="Code" label="成本中心编码" width="100" />
             <el-table-column prop="Name" label="名称" width="100" />
          <el-table-column
            prop="DepartmentNames"
            label="部门名称"
            width="100"
          />

          <el-table-column prop="CompanyNames" label="单位名称" width="100" />
          <el-table-column prop="ProjectNames" label="项目部名称" width="150" />
          <el-table-column prop="Sponsor" label="签证主体" width="100" >
             <template slot-scope="scope">
              {{ scope.row.Sponsor | filterSponsor(sponsorList)}}
             </template>
        </el-table-column>
          
          <el-table-column prop="Remarks" label="备注" width="150" />
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <i
                class="fa fa-edit"
                v-if="powers.update"
                @click="edit(scope.row)"
              ></i>
              <i
                class="fa fa-trash"
                v-if="powers.delete"
                @click="deleteCostCenters(scope.row)"
              ></i>
           
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="getCostCenters(1)"
          @current-change="getCostCenters"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="dialogType === 1 ? '新建成本中心' : '编辑成本中心'"
      :visible.sync="dialogVisible"
      width="900px"
      :before-close="handleClose"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="from-box">
            <div class="name">成本中心编码:</div>
            <el-input
              v-model="parameter.Code"
              placeholder="成本中心编码"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">成本中心名称:</div>
            <el-input
              v-model="parameter.Name"
              placeholder="成本中心名称"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">是否启用:</div>
            <el-switch v-model="parameter.Enabled"> </el-switch>
          </div>

          <div class="from-box">
            <div class="name">签证主体:</div>
            <el-select v-model="parameter.Sponsor" placeholder="请选择" filterable
              clearable>
              <el-option
                v-for="item in sponsorList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="line-box">
          <div class="single">
            <div class="name">描述:</div>
            <el-input
              v-model="parameter.Description"
              placeholder="描述"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="single">
            <div class="name">所属项目部:</div>
            <el-cascader
              v-model="parameter.ProjectIds"
              :options="tree2"
              :props="props2"
              clearable
              filterable
            ></el-cascader>
          </div>
        </div>

         <div class="line-box">
          <div class="single">
            <div class="name">所属单位:</div>
            <el-select v-model="parameter.CompanyId" placeholder="请选择" filterable
              clearable>
              <el-option
                v-for="item in companyList"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="single">
            <div class="name">所属部门:</div>
            <el-cascader
              v-model="parameter.DepartmentIds"
              :options="departmentsList"
              :props="props3"
              clearable
              filterable
            ></el-cascader>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">取消</el-button
        ><el-button class="pop-save" @click="addUpRoles">保存</el-button>
      </div>
    </el-dialog>

               <el-dialog
      title="导入成本中心"
      :visible.sync="dialogVisible3"
      width="600px"
      :before-close="handleClose3"
      top="50px"
      class="dialog"
    >

    <div class="text-box">
      <div class="text-title">模板文件</div>
      <div class="text-name" @click="getDownloadUrl">成本中心批量导入模板</div>
    </div>
      <div class="upload-box">
        <div class="left">
          <div v-if="name2" class="name">
             <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
             {{name2}}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachment2"
          ref="uploadRef"
        >
          <button  class="btn-up"><svg-icon class="icon" icon-class="file"></svg-icon></svg>选择 ...</button>
        </el-upload>
      </div>
      <div class="dialog-footer2">
        <el-button class="pop-close" @click="handleClose3">关闭</el-button>
        <el-button class="pop-save pop-right" @click="addAttachment2">导入</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { auditRecords } from "@/utils/tableData";
import { recursion } from "@/utils/recursion";
import {
  getCostCenters,
  addCostCenters,
  updateCostCenters,
  deleteCostCenters,
  getProjectsTree,
  getOrganizationTree,
  getCompanyAll,
  getDepartmentsTree,
  getCostCentersTemplate,
  uploadAttachment,
  getCostCentersImport,
  getCostCentersExport,
} from "@/api/user";
export default {
  filters: {
    filterSponsor(data, list) {
      let name = "";
      list.forEach((item) => {
        if (item.value === data) {
          name = item.label;
        }
      });

      return name;
    },
  },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      auditRecords: auditRecords,
      dialogVisible: false,
      dialogType: 1, //1新建2、编辑
      options1: null,
      treeIds: [],
      sponsorList: [
        {
          value: 1,
          label: "SNEI",
        },
        {
          value: 2,
          label: "SEG",
        },
        {
          value: 3,
          label: "其他",
        },
      ],
      parameter: {
        Id: 0,
        Code: "",
        Name: "",
        ProjectIds: [],
        CompanyId: null,
        DepartmentIds: [],
        Description: "",
        Enabled: true,
        State: 0,
        Sponsor: null,
      },
      props1: {
        value: "TreeId",
        label: "Name",
        children: "Children",
        checkStrictly: true,
      },
      props2: {
        value: "ProjectID",
        label: "ProjectName",
        children: "Children",
        checkStrictly: true,
      },
      props3: {
        value: "ID",
        label: "DepartmentCHS",
        children: "Children",
        checkStrictly: true,
      },
      tree1: null,
      tree2: null,
      senior: false,
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      value5: null,
      value6: null,
      radio: "1",
      statesRadio: "",
      textarea: "",
      roleName: "",
      enable: false,
      code: null,
      page: 1,
      size: 10,
      total: null,
      Id: 0,
      ParentId: 0,
      DepartmentId: 0,
      companyList: [],
      departmentsList: [],
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      dialogVisible3: false,
      downloadUrl: null,
      uploadUrl: null,
      name2: null,
      loading: true,
    };
  },

  methods: {
    deleteItem(index) {
      this.messageList.splice(index, 1);
    },
    handleNoticeClose() {
      this.noticeDrawer = false;
    },

    handleClose() {
      this.dialogVisible = false;
      this.parameter = {
        Id: 0,
        Code: "",
        ProjectIds: [],
        CompanyId: null,
        DepartmentIds: [],
        Description: "",
        Enabled: true,
        State: 0,
        Name: "",
        Sponsor: "",
      };
      this.treeIds = [];
    },
    handleClose3() {
      this.dialogVisible3 = false;
      this.name2 = null;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    newBuilt() {
      this.dialogVisible = true;
      this.dialogType = 1;
    },
    edit(row) {
      this.dialogVisible = true;
      this.dialogType = 2;
      const multipleSelectionItem = row;
      const treeParentIds = multipleSelectionItem.TreeParentIds.replace(
        /\//g,
        ","
      );

      this.treeIds = treeParentIds.split(",");
      this.parameter = {
        Id: multipleSelectionItem.Id,
        Code: multipleSelectionItem.Code,
        Name: multipleSelectionItem.Name,
        ProjectIds: [],
        CompanyId: null,
        DepartmentIds: [],
        Description: multipleSelectionItem.Remarks,
        Enabled: multipleSelectionItem.Enabled,
        State: multipleSelectionItem.State,
        Sponsor: multipleSelectionItem.Sponsor,
      };

      if (multipleSelectionItem.CompanyId) {
        this.parameter.CompanyId = multipleSelectionItem.CompanyId;
      }
      if (multipleSelectionItem.ProjectIds) {
        this.parameter.ProjectIds =
          multipleSelectionItem.ProjectIds.split(",").map(Number);
      }
      if (multipleSelectionItem.DepartmentIds) {
        this.parameter.DepartmentIds =
          multipleSelectionItem.DepartmentIds.split(",").map(Number);
      }
    },

    //获取角色列表
    getCostCenters(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        code: this.code,
      };
      getCostCenters(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //添加编辑角色
    addUpRoles() {
      if (!this.parameter.Name) {
        this.parameter.Name = this.parameter.Code;
      }
      if (this.dialogType === 1) {
        //新增
        addCostCenters(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("创建成功");
            this.getCostCenters();
            this.handleClose();
          }
        });
      } else {
        updateCostCenters(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.getCostCenters();
            this.handleClose();
          }
        });
      }
    },
    //删除成本中心
    deleteCostCenters(row) {
      let ids = [];
      ids.push(row.Id);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCostCenters(ids).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getCostCenters();
          }
        });
      });
    },
    getTree1() {
      getOrganizationTree().then((res) => {
        if (res.status === 200) {
          this.tree1 = res.response;
        }
      });
    },
    getTree2() {
      getProjectsTree().then((res) => {
        if (res.status === 200) {
          this.tree2 = res.response;
        }
      });
    },
    //获取所有单位
    getCompanyAll() {
      getCompanyAll().then((res) => {
        if (res.status === 200) {
          this.companyList = res.response;
        }
      });
    },
    //所属部门
    getDepartmentsTree() {
      getDepartmentsTree().then((res) => {
        if (res.status === 200) {
          this.departmentsList = res.response;
        }
      });
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    uploadAllInfo() {
      getCostCentersTemplate().then((res) => {
        if (res.status === 200) {
          this.dialogVisible3 = true;
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          this.downloadUrl = "https://hrms.baishulai.com/" + url;
        }
      });
    },

    getDownloadUrl() {
      location.href = this.downloadUrl;
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "xls";
      const suffix2 = img === "xlsx";
      if (!suffix1 && !suffix2) {
        this.$message.error("只能上传后缀为xls、xlsx的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name2 = data.name;
          this.uploadUrl = res.response;
        }
      });
    },
    addAttachment2() {
      const data = {
        Path: this.uploadUrl,
        KeyID: this.KeyID,
      };
      getCostCentersImport(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("导入成功");
          this.handleClose3();
        }
      });
    },
    downloadAll() {
      getCostCentersExport().then((res) => {
        if (res.status === 200) {
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          location.href = "https://hrms.baishulai.com/" + url;
        }
      });
    },
  },

  created() {
    this.getCostCenters();
    this.getTree1();
    this.getCompanyAll();
    this.getTree2();
    this.getDepartmentsTree();
    this.getButtonPower();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px 0;
    .screen-box {
      display: flex;

      align-items: center;
      position: relative;
      padding: 0 12px;
      .el-input {
        width: 180px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 65px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
    }
  }
  .dialog-main {
    border-bottom: 2px solid #e6e6e6;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 370px;
        margin-bottom: 24px;
        .name {
          width: 110px;
          font-size: 16px;
          flex-shrink: 0;
          margin-right: 10px;
        }
        .el-input {
          width: 250px;
        }
        .el-select {
          width: 250px;
        }
      }
      .single {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        .name {
          flex-shrink: 0;
          width: 110px;
          margin-right: 10px;
        }
        .el-cascader {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
      }
    }
  }
  .dialog-footer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.text-box {
  display: flex;
  color: #000000;
  .text-title {
    margin-right: 24px;
  }
  .text-name {
    color: #0076ff;
    cursor: pointer;
  }
}
.upload-box {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.dialog-footer2 {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
</style>
